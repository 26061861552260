<template>
  <div>
    <div
      class="header-menu__back button button_empty-pink button_small"
      v-if="parent && false"
      @click="$emit('back')"
    >
      <span class="mr-2">
        <svg
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 1L1 6L6 11"
            stroke="currentColor"
            stroke-linecap="square"
          />
        </svg>
      </span>
      Назад
    </div>
    <div class="header-menu__title" v-if="false">
      {{ parent ? parent.title : "Меню" }}
    </div>
    <div
      v-for="(item, ind) in items.filter((el) => !el.hide)"
      :key="ind"
      v-show="!item.hide"
      class="header-menu__group"
      :class="{ 'header-menu__group_opened': itemOpened(`${level}_${ind}`) }"
    >
      <div class="header-menu__item">
        <div
          @click="toMenuItem(item.link, item.title, item.childrens || item.diseases, `${level}_${ind}`)"
          :data-id="item.ref"
          class="header-menu__link"
          v-html="item.title"
        >
        </div>
        <div
          class="header-menu__item-icon"
          @click="toggleItem(`${level}_${ind}`)"
          v-if="item.childrens || item.diseases"
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L1 11" stroke="#3C4242" stroke-linecap="square" />
          </svg>
        </div>
      </div>

      <div class="header-menu__list" v-if="item.childrens">
        <HeaderMenuBlock
          :level="level + 1"
          :items="item.childrens"
          :parent="item"
          @back="back"
          @menuOpen="$emit('menuOpen', true)"
        />
      </div>
      <div
        class="header-menu__list"
        v-if="item.diseases && item.diseases.length"
      >
        <HeaderMenuBlock
          :level="level + 1"
          :items="item.diseases"
          :parent="item"
          @back="back"
          @menuOpen="$emit('menuOpen', true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenuBlock from "./HeaderMenuBlock";
import { bus } from "@/main";
export default {
  name: "HeaderMenuBlock",
  components: {
    HeaderMenuBlock,
  },
  props: {
    items: Array,
    level: Number,
    parent: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    isOpened: [],
  }),
  computed: {},
  methods: {
    itemOpened(id) {
      return this.isOpened.includes(id);
    },
    toMenuItem(to, item, hasChildren, id) {
      // console.log(hasChildren)
      // if((hasChildren && hasChildren.length > 0) && id) {
      //   this.toggleItem(id)
      //   return
      // }
      if (!to) return;
      bus.$emit("scrollLock", false);
      const vm = this;
      let obj = {};
      if (this.$parent?.parent) {
        obj = {
          first: this.$parent?.parent.title,
          second: this.parent.title,
        };
      } else if (this.parent) {
        obj = {
          first: this.parent.title,
          second: item,
        };
      } else {
        obj = {
          first: item,
          second: "None",
        };
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "header menu click", {
          "header menu click": {
            "menu item": item,
            "first menu level": obj.first,
            "second menu level": obj.second,
            ...this.$root.ymFields,
          },
        });
      }
      if (typeof to !== "object") {
        window.open(to, "_blank");
      } else {
        this.$router.push(to).catch(() => {
          this.$emit("closeMenu");
        });
      }
    },
    back() {
      // this.$emit("menuOpen", false);
      // this.$set(this, "isOpened", null);
    },
    toggleItem(id) {
      let item = this.isOpened.findIndex((el) => el === id);
      // this.$emit("menuOpen", item > -1);
      if (item > -1) {
        this.isOpened.splice(item, 1);
      } else {
        this.isOpened.push(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-menu {
  &__title {
    cursor: pointer;
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__back {
    margin-bottom: 36px;
    width: 80px;
    height: 28px;
  }

  &__item-icon {  
    padding-left: 15%;

    & > svg {
      transform: rotate(0);
      transition: 0.3s;
    }
  }

  &__group {
    &_opened {
      > .header-menu__list {
        left: 0;
        max-height: 10000px;
        padding: 16px;
      }

      > .header-menu__item .header-menu__item-icon {

        & > svg {
          transform: rotate(90deg);
        }
        
      }
    }
  }

  &__item {
    cursor: pointer;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    cursor: pointer;
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
    width: 100%;
    margin-right: 16px;
  }

  &__list {
    // position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: 0.3s;
    // padding: 0 24px;
    padding: 0 16px;
    z-index: 10;

    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
}
</style>